import MemberHeader, { Props } from './render';
import { useAppDispatch, useAppSelector } from '@/core';
import enumsSelector from '@/core/selectors/enums';
import userSelector from '@/core/selectors/users';
import persistSelector from '@/core/selectors/persist';
import errorSelector from '@/core/selectors/error';
import { WrapperProps } from '@/types/generic';
import { useCallback } from 'react';
import { actionUsers } from '@/core/reducers/users';
import { actionsError } from '@/core/reducers/error';
import { actionProviders } from '@/core/reducers/providers';
import { actionEnums } from '@/core/reducers/enums';

type ComponentProp = WrapperProps<
  Props,
  {
    labels: unknown;
    initLogout: unknown;
    sagasLoaded: unknown;
    getAuthentication: unknown;
    initLogin: unknown;
    userAuth: unknown;
    error: unknown;
    clearError: unknown;
    getProviders: unknown;
    userPersist: unknown;
    languages: unknown;
    callGetGlobalEnums: unknown;
  }
>;
const MemberHeaderConnected = (props: ComponentProp) => {
  const labels = {
    dashboardOption: useAppSelector(state =>
      enumsSelector.getLiteralEnum(state, 'MEMBER_HEADER_DASH'),
    ),
    knowledgeOption: useAppSelector(state =>
      enumsSelector.getLiteralEnum(state, 'MEMBER_HEADER_KNOW'),
    ),
    logout: useAppSelector(state =>
      enumsSelector.getLiteralEnum(state, 'WCONNECT_LOGOUT'),
    ),
    login: useAppSelector(state =>
      enumsSelector.getLiteralEnum(state, 'WCONNECT_BUTTON'),
    ),
  };

  const globalLangugagesText = useAppSelector(state =>
    enumsSelector.getLiteralEnum(state, 'GLOBAL_LANGUAGES'),
  );
  const languages = globalLangugagesText.split(',');

  const userAuth = useAppSelector(state => userSelector.getUser(state));
  const error = useAppSelector(state => errorSelector.getError(state));
  const sagasLoaded = useAppSelector(state =>
    userSelector.getSagasLoaded(state),
  );
  const userPersist = useAppSelector(state =>
    persistSelector.userPersist(state),
  );

  const dispatch = useAppDispatch();
  const getAuthentication = useCallback(() => {
    dispatch(actionUsers.getAuthentication());
  }, [dispatch]);
  const initLogin = useCallback(() => {
    dispatch(actionUsers.initLogin());
  }, [dispatch]);
  const clearError = useCallback(() => {
    dispatch(actionsError.clearError());
  }, [dispatch]);
  const getProviders = useCallback(() => {
    dispatch(actionProviders.getProvidersList());
  }, [dispatch]);
  const initLogout = useCallback(() => {
    dispatch(actionUsers.initLogout());
  }, [dispatch]);
  const callGetGlobalEnums = useCallback(
    (db: string) => {
      dispatch(actionEnums.getGlobalEnums({ db }));
    },
    [dispatch],
  );

  return (
    <MemberHeader
      {...props}
      labels={labels}
      sagasLoaded={sagasLoaded}
      getAuthentication={getAuthentication}
      initLogin={initLogin}
      initLogout={initLogout}
      userAuth={userAuth}
      error={error}
      clearError={clearError}
      getProviders={getProviders}
      userPersist={userPersist}
      languages={languages}
      callGetGlobalEnums={callGetGlobalEnums}
    />
  );
};
export default MemberHeaderConnected;
