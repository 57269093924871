import { createGlobalStyle, DefaultTheme } from 'styled-components';
import { Helmet } from 'react-helmet-async';

export const GoogleFontInjection = () => {
  return (
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap"
        rel="stylesheet"
      ></link>
    </Helmet>
  );
};

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      black: string;
      blackOpacity: string;
      realBlack: string;
      white: string;
      whiteSoft: string;
      customWhite: string;
      lightGreen: string;
      green: string;
      customPrimary: string;
      primary: string;
      secundary: string;
      grayLight: string;
      softGray: string;
      gray: string;
      tableGray: string;
      blue: string;
      lightBlue: string;
      darkBlue: string;
      success: string;
      error: string;
      link: string;
      softYellow: string;
      yellow: string;
      darkPrimary: string;
      lightPrimary: string;
      panelBackground: string;
      line: string;
      tooltipBackground: string;
      red: string;
      disable: string;
      inputBackground: string;
      lightBackgroud: string;
      gradientBlue: string;
      gradientGreen: string;
      darkGray: string;
    };
    fonts: {
      Poppins: string;
    };
    sizes: {
      panelMaxWidth: number;
      panelBorderRadius: number;
    };
  }
}

const AppTheme: DefaultTheme = {
  colors: {
    black: '#1d262c',
    blackOpacity: '#212530',
    white: '#FFF',
    whiteSoft: '#dcdcdc',
    customWhite: '#FFFFF6',
    customPrimary: '#4453e2',
    primary: '#20204F',
    secundary: '#4800ff',
    grayLight: '#E5E5E5',
    softGray: '#f3f3f3',
    gray: '#909090',
    tableGray: '#eff2f8',
    realBlack: '#000',
    lightGreen: '#81D5D0',
    green: '#17d781',
    blue: '#838EFA',
    lightBlue: '#0091ff',
    darkBlue: '#323260',
    success: '#26D76D',
    error: '#FF7575',
    link: '#0091ff',
    softYellow: '#FFFBE3',
    yellow: '#FFDE1A',
    darkPrimary: '#030613',
    lightPrimary: '#010924',
    panelBackground: '#0a1435',
    line: '#424d70',
    tooltipBackground: '#070606',
    red: '#fa5a59',
    disable: '#29304b',
    inputBackground: '#0b1a49',
    lightBackgroud: '#fbfbfb',
    gradientBlue: '#108ee9',
    gradientGreen: '#87d068',
    darkGray: '#3b3b3b',
  },
  fonts: {
    Poppins: "'Poppins', sans-serif",
  },
  sizes: {
    panelMaxWidth: 600,
    panelBorderRadius: 7,
  },
};

export const GlobalStyle = createGlobalStyle`
  body {
    margin:0;
    background: ${AppTheme.colors.lightPrimary};
  }
  
  .ant-statistic-title {
    font-family: ${AppTheme.fonts.Poppins};
    color: ${AppTheme.colors.black};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .ant-statistic-content {
    font-family: ${AppTheme.fonts.Poppins};
    color: ${AppTheme.colors.blue};
    font-weight: 500;
    font-size: 36px;
    line-height: 39px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .ant-statistic-content-suffix {
    margin-left: 12px;
  }
  .ant-table-tbody > tr > td {
    border: inherit;
    font-family: ${AppTheme.fonts.Poppins};
  }
  .ant-table-thead > tr > th {
    background: inherit;
    font-family: ${AppTheme.fonts.Poppins};
  }
  .col-auto-height {
    align-self: flex-start;
  }
  .ant-tooltip-inner {
    font-family: ${AppTheme.fonts.Poppins};
    font-weight: 300;
    font-size: 14px;
    background-color: ${AppTheme.colors.tooltipBackground};
    padding: 12px;
    border-radius: 5px;
  }
  .ant-form-item-explain-error {
    font-family: ${AppTheme.fonts.Poppins};
  }
  .ant-notification-notice {
    font-family: ${AppTheme.fonts.Poppins};
    font-weight: 300;
    font-size: 14px;
    border-radius: 10px;
  }
  .anticon.ant-notification-notice-icon-success {
    color: ${AppTheme.colors.secundary};
  }
  .centerFlexItems {
    display: flex;
    align-items: center;
  }
  .flexOne {
    flex: 1;
  }
  .capitalizeText {
    text-transform: capitalize
  }
  .textEllipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export default AppTheme;
