import styled, { createGlobalStyle } from 'styled-components';

type ContainerType = {
  withBackground?: boolean;
};
export const MemberHeaderContainer = styled.div<ContainerType>`
  ${({ withBackground, theme }) =>
    withBackground === true &&
    `
    background: ${theme.colors.darkPrimary};
  `}
  position: relative;
  margin: auto;
  height: 70px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  direction: row;
  align-items: center;
  height: 100%;
  padding: 0px 30px;
`;

type LogoContainerType = {
  logoHeight?: number;
};
export const LogoContainer = styled.div<LogoContainerType>`
  height: ${({ logoHeight }) => logoHeight || 50}px;
  min-width: 240px;
  img {
    height: 100%;
    cursor: pointer;
    max-width: 230px;
    object-fit: contain;
  }
`;

export const UserMenuItem = styled.a`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;

export const LanguageButton = styled.div`
  margin: 0px 20px;
  .ant-btn-icon-only.ant-btn-lg > * {
    font-size: 28px;
  }
  .ant-btn {
    color: #8e8e8e;
  }
`;

export const MenuButton = styled.div`
  margin: 0px 20px;
  .ant-btn-icon-only.ant-btn-lg > * {
    font-size: 28px;
  }
  .ant-btn svg {
    color: ${({ theme }) => theme.colors.white} !important;
  }
`;

export const MenuDesktopContainer = styled.div`
  flex: 1;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
`;

export const DesktopUserAvatar = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const GlobalMenuStyle = createGlobalStyle`
  .ant-dropdown-menu {
    width: fit-content !important;
    margin-top: 12px;
    border-radius: ${({ theme }) => theme.sizes.panelBorderRadius}px !important;
    min-width: 200px !important;
  }
  .ant-dropdown-menu-item {
    padding: 10px 20px;
    font-family: ${({ theme }) => theme.fonts.Poppins};
    color: ${({ theme }) => theme.colors.black};
    a {
      color: ${({ theme }) => theme.colors.black};
    }
  }
  .ant-dropdown-menu-item-group-title {
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.Poppins};
  }
  .logoutMobile {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
`;
