import { combineReducers } from 'redux';
import user from './users';
import error from './error';
import providers from './providers';
import enums from './enums';
import pool from './pool';
import deposits from './deposits';
import persist from './persist';
import qualification from './qualification';
import properties from './properties';
import dynamicform from './dynamicform';

const rootReducer = combineReducers({
  user,
  error,
  providers,
  enums,
  pool,
  deposits,
  persist,
  qualification,
  properties,
  dynamicform,
});
export default rootReducer;
