import { lazy, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ROUTES } from '@/config/constant';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import userSelector from '@/core/selectors/users';
import persistSelector from '@/core/selectors/persist';
import enumsSelector from '@/core/selectors/enums';
import { Steps } from 'antd';
import {
  SolutionOutlined,
  UserOutlined,
  BankOutlined,
  FileDoneOutlined,
} from '@ant-design/icons';
import { LazyRender } from '@/app/components/LazyRender';
import MemberHeader from '@/app/components/MemberHeader';
import {
  PrequalifiedSiteContainer,
  GlobalPrequalifyStyle,
  StepsContainer,
} from './styled';
import { useAppDispatch, useAppSelector } from '@/core';
import {
  AuthUser,
  PrequalificationData,
  SupportCountries,
  UserPersist,
  UserStates,
} from '@/core/interfaces';
import useIsMobile from '@/app/hooks/useIsMobile';
import { actionUsers } from '@/core/reducers/users';

const PersonalDataPage = lazy(
  () => import('@/app/Pages/Prequalification/PersonalData'),
);
const BankDataWrapper = lazy(
  () => import('@/app/Pages/Prequalification/BankData'),
);
const IncomeData = lazy(
  () => import('@/app/Pages/Prequalification/IncomeData'),
);
const Result = lazy(() => import('@/app/Pages/Prequalification/PreqResult'));
const USFlow = lazy(() => import('@/app/Pages/Prequalification/USFlow'));

const PageNotFound = lazy(() => import('@/app/Pages/NotFound'));

const { Step } = Steps;
const Prequalification = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [disableSteps, setDisableStep] = useState(false);
  const isMobile = useIsMobile();
  const [hasResult, setHasResult] = useState(false);
  const [isUSFlow, setIsUSFlow] = useState<boolean | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const labels = {
    navUsr: useAppSelector(state =>
      enumsSelector.getLiteralEnum(state, 'PREQUALIFY_NAV_USR'),
    ),
    navUsrDesc: useAppSelector(state =>
      enumsSelector.getLiteralEnum(state, 'PREQUALIFY_NAV_USR_DESC'),
    ),
    navFunds: useAppSelector(state =>
      enumsSelector.getLiteralEnum(state, 'PREQUALIFY_NAV_FUNDS'),
    ),
    navFundsDesc: useAppSelector(state =>
      enumsSelector.getLiteralEnum(state, 'PREQUALIFY_NAV_FUNDS_DESC'),
    ),
    navIncome: useAppSelector(state =>
      enumsSelector.getLiteralEnum(state, 'PREQUALIFY_NAV_INCOME'),
    ),
    navIncomeDesc: useAppSelector(state =>
      enumsSelector.getLiteralEnum(state, 'PREQUALIFY_NAV_INCOME_DESC'),
    ),
    navResult: useAppSelector(state =>
      enumsSelector.getLiteralEnum(state, 'PREQUALIFY_NAV_RS'),
    ),
    navResultDesc: useAppSelector(state =>
      enumsSelector.getLiteralEnum(state, 'PREQUALIFY_NAV_RS_DESC'),
    ),
    navFundsUS: useAppSelector(state =>
      enumsSelector.getLiteralEnum(state, 'PREQUALIFY_NAV_FUNDS_US'),
    ),
    navFundsDescUS: useAppSelector(state =>
      enumsSelector.getLiteralEnum(state, 'PREQUALIFY_NAV_FUNDS_US_DESC'),
    ),
    navDocsUS: useAppSelector(state =>
      enumsSelector.getLiteralEnum(state, 'PREQUALIFY_NAV_DOCS'),
    ),
    navDocsDescUS: useAppSelector(state =>
      enumsSelector.getLiteralEnum(state, 'PREQUALIFY_NAV_DOCS_DESC'),
    ),
  };

  const prequalificationData: PrequalificationData = useAppSelector(state =>
    userSelector.getPrequalificationData(state),
  );
  const userAuth: AuthUser = useAppSelector(state =>
    userSelector.getUser(state),
  );
  const userPersist: UserPersist = useAppSelector(state =>
    persistSelector.userPersist(state),
  );
  const userCountry = useAppSelector(state =>
    userSelector.getUserCountry(state),
  );
  const dispatch = useAppDispatch();
  const callGetUserCountry = useCallback(() => {
    dispatch(actionUsers.getUserCountry());
  }, [dispatch]);

  useEffect(() => {
    const hasResultCheck =
      (userAuth?.last_state || userAuth?.user?.last_state) ===
        UserStates.PREQUALIFICATION_PROCESSING ||
      (userAuth?.last_state || userAuth?.user?.last_state) ===
        UserStates.PREQUALIFIED;
    setDisableStep(!userPersist?.firstname || hasResultCheck);
    setHasResult(hasResultCheck);
  }, [userAuth, userPersist]);

  useEffect(() => {
    if (
      userCountry === SupportCountries.US ||
      userCountry === SupportCountries.USA
    ) {
      setIsUSFlow(true);
    } else if (!userCountry) {
      callGetUserCountry();
    } else {
      setIsUSFlow(false);
    }
  }, [callGetUserCountry, userCountry]);

  useEffect(() => {
    switch (location.pathname) {
      case ROUTES.PREQUALIFIED.MAIN:
        setCurrentStep(0);
        break;
      case ROUTES.PREQUALIFIED.FUNDS:
      case ROUTES.PREQUALIFIED.USFLOW.MAIN_ROUTER:
        setCurrentStep(1);
        break;
      case ROUTES.PREQUALIFIED.INCOME:
      case ROUTES.PREQUALIFIED.USFLOW.DOCUMENTS_ROUTER:
        setCurrentStep(2);
        break;
      case ROUTES.PREQUALIFIED.RESULT:
        setCurrentStep(3);
        break;
    }
  }, [location]);

  useEffect(() => {
    if (currentStep === 1 || currentStep === 2) {
      if (!userPersist?.firstname) {
        navigate(ROUTES.PREQUALIFIED.MAIN);
      } else if (hasResult && isUSFlow === false && !!userCountry) {
        navigate(ROUTES.PREQUALIFIED.RESULT);
      }
    }
  }, [
    currentStep,
    hasResult,
    isUSFlow,
    navigate,
    userCountry,
    userPersist?.firstname,
  ]);

  return (
    <PrequalifiedSiteContainer>
      <Helmet titleTemplate="%s - Household" defaultTitle="Household">
        <meta name="description" content="Household app" />
      </Helmet>
      <GlobalPrequalifyStyle />
      <MemberHeader withColor />
      <Routes>
        <Route
          path={'/'}
          element={
            <LazyRender>
              <PersonalDataPage />
            </LazyRender>
          }
        />
        <Route
          path={ROUTES.PREQUALIFIED.FUNDS_ROUTER}
          element={
            <LazyRender>
              <BankDataWrapper />
            </LazyRender>
          }
        />
        <Route
          path={ROUTES.PREQUALIFIED.INCOME_ROUTER}
          element={
            <LazyRender>
              <IncomeData />
            </LazyRender>
          }
        />
        <Route
          path={ROUTES.PREQUALIFIED.RESULT_ROUTER}
          element={
            <LazyRender>
              <Result />
            </LazyRender>
          }
        />
        <Route
          path={`${ROUTES.PREQUALIFIED.USFLOW.MAIN}/*`}
          element={
            <LazyRender>
              <USFlow />
            </LazyRender>
          }
        />
        <Route
          path={'/*'}
          element={
            <LazyRender>
              <PageNotFound />
            </LazyRender>
          }
        />
      </Routes>
      <StepsContainer isMobile={isMobile}>
        <Steps size="small" current={currentStep} type="navigation">
          {(!isMobile || currentStep === 0) && (
            <Step
              title={labels.navUsr}
              description={labels.navUsrDesc}
              icon={<UserOutlined />}
              disabled={!!userPersist?.firstname}
            />
          )}
          {(!isMobile || currentStep === 1) && (
            <Step
              title={isUSFlow ? labels.navFundsUS : labels.navFunds}
              icon={<BankOutlined />}
              description={
                isUSFlow ? labels.navFundsDescUS : labels.navFundsDesc
              }
              disabled={disableSteps}
            />
          )}
          {(!isMobile || currentStep === 2) && (
            <Step
              title={isUSFlow ? labels.navDocsUS : labels.navIncome}
              icon={<SolutionOutlined />}
              description={
                isUSFlow ? labels.navDocsDescUS : labels.navIncomeDesc
              }
              disabled={
                disableSteps || prequalificationData?.banksAccount?.length === 0
              }
            />
          )}
          {(!isMobile || currentStep === 3) && (
            <Step
              title={labels.navResult}
              icon={<FileDoneOutlined />}
              description={labels.navResultDesc}
              disabled={!disableSteps || !hasResult}
            />
          )}
        </Steps>
      </StepsContainer>
    </PrequalifiedSiteContainer>
  );
};

export default Prequalification;
