import { createSlice } from '@reduxjs/toolkit';

export const userKeys = {
  NAME: 'user',
  SAGAS_LOADED: 'sagasloaded',
  LOGOUT: 'logout',
};

const INTIAL_STATE = {
  [userKeys.SAGAS_LOADED]: false,
  [userKeys.LOGOUT]: false,
};

const userSlice = createSlice({
  name: userKeys.NAME,
  initialState: INTIAL_STATE,
  reducers: {
    set(state, action) {
      const { key, value } = action.payload;
      const newState: any = { ...state };
      newState[key] = value;
      return newState;
    },
    reset(state) {
      return {
        ...INTIAL_STATE,
        [userKeys.SAGAS_LOADED]: state[userKeys.SAGAS_LOADED],
      };
    },
    resetKeys(state, action) {
      const { keys } = action.payload;
      const newState: any = { ...state };
      keys.forEach((k: string) => {
        newState[k] = null;
      });
      return newState;
    },
    sagaLoaded(state) {
      state[userKeys.SAGAS_LOADED] = true;
    },
    setUser(_state, _action) {},
    getCountries() {},
    getAuthentication() {},
    initLogin() {},
    initLoginEmail(_state, _action) {},
    makeOtpValidation(_state, _action) {},
    initLogout() {},
    initPersonaFlow(_state, _action) {},
    registerNewUser(_state, _action) {},
    reqPrequalificationData() {},
    setPrequalificationIncome(_state, _action) {},
    setPrequalificationExtraFunds(_state, _action) {},
    setPrequalificationDocs(_state, _action) {},
    getUserCountry() {},
  },
});

export const actionUsers = userSlice.actions;

export default userSlice.reducer;
