import { createSlice } from '@reduxjs/toolkit';

export const quialificationKeys = {
  NAME: 'qualification',
};
const initial: {
  [k: string]: any;
} = {};
const qualificationSlice = createSlice({
  name: quialificationKeys.NAME,
  initialState: initial,
  reducers: {
    set(state, action) {
      const { key, value } = action.payload;
      return void (state[key] = value);
    },
    updateObject(state, action) {
      const { objectName, key, value } = action.payload;
      if (!state[objectName]) {
        state[objectName] = {};
      }
      return void (state[objectName][key] = value);
    },
    reset() {
      return {};
    },
    resetKeys(state, action) {
      const { keys } = action.payload;
      const newState: any = { ...state };
      keys.forEach((k: string) => {
        newState[k] = null;
      });
      return newState;
    },
    getFinancialStatement() {},
    saveDynamicForm(_state, _action) {},
    presignUrl(_state, _action) {},
    uploadFile(_state, _action) {},
  },
});

export const actionQualification = qualificationSlice.actions;

export default qualificationSlice.reducer;
