import { createSelector } from 'reselect';
import { actionUsers, userKeys } from '@/core/reducers/users';

const userFromState = (state: any) => state[userKeys.NAME];

const getUser = createSelector(
  [userFromState],
  user => user[actionUsers.setUser.type],
);

const getSagasLoaded = createSelector(
  [userFromState],
  user => user[userKeys.SAGAS_LOADED],
);

const getSigninEmail = createSelector(
  [userFromState],
  user => user[actionUsers.initLoginEmail.type],
);

const getPersonaStatus = createSelector(
  [userFromState],
  user => user[actionUsers.initPersonaFlow.type],
);

const getCountries = createSelector(
  [userFromState],
  user => user[actionUsers.getCountries.type],
);

const getPrequalificationData = createSelector(
  [userFromState],
  user => user[actionUsers.reqPrequalificationData.type],
);

const getUserCountry = createSelector(
  [userFromState],
  user => user[actionUsers.getUserCountry.type],
);

const getPreqExtraFundsReport = createSelector(
  [userFromState],
  user => user[actionUsers.setPrequalificationExtraFunds.type],
);

const needLogout = createSelector(
  [userFromState],
  user => user[userKeys.LOGOUT],
);

const selectors = {
  getUser,
  getSagasLoaded,
  getSigninEmail,
  getPersonaStatus,
  getCountries,
  getPrequalificationData,
  getUserCountry,
  getPreqExtraFundsReport,
  needLogout,
};
export { selectors as default };
