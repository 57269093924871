import { ROUTES } from '@/config/constant';
import { useAppDispatch, useAppSelector } from '@/core';
import { AuthUser, Company, UserPersist, UserStates } from '@/core/interfaces';
import { actionUsers } from '@/core/reducers/users';
import userSelector from '@/core/selectors/users';
import enumsSelector from '@/core/selectors/enums';
import persistSelector from '@/core/selectors/persist';
import { lazy, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import DynamicSagaLoader from './components/DynamicSagaLoder';
import { LazyRender } from './components/LazyRender';
import LoadingView from './components/LoadingView';
import { setDefaultToken } from '@/core/api/api-services';
import Prequalification from './Pages/Prequalification';

const PageNotFound = lazy(() => import('@/app/Pages/NotFound'));
const SignInPage = lazy(
  () => import(/* webpackChunkName: "home" */ '@/app/Pages/Home'),
);

const Accounting = lazy(
  () => import(/* webpackChunkName: "accounting" */ '@/app/Pages/MemberSite'),
);

const PrepareRender = (props: {
  loading: boolean;
  children: JSX.Element;
  isPrivate: boolean;
  isValid?: boolean;
  state?: UserStates;
}) => {
  const { loading, children, isPrivate, isValid, state } = props;
  const switchState = () => {
    switch (state) {
      case UserStates.PREQUALIFICATION_FORM:
        return (
          <Navigate
            to={{
              pathname: ROUTES.PREQUALIFIED.MAIN,
            }}
          />
        );
      default:
        return <LazyRender>{children}</LazyRender>;
    }
  };

  return loading ? (
    <LoadingView />
  ) : !isPrivate || (isPrivate && isValid) ? (
    switchState()
  ) : (
    <Navigate
      to={{
        pathname: ROUTES.HOME,
      }}
    />
  );
};
const AppRouter = () => {
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [userExist, setUserExist] = useState(false);
  const userAuth: AuthUser = useAppSelector(state =>
    userSelector.getUser(state),
  );
  const userPersist: UserPersist = useAppSelector(state =>
    persistSelector.userPersist(state),
  );
  const userToken: string = useAppSelector(state =>
    persistSelector.getUserToken(state),
  );
  const sagasLoaded = useAppSelector(state =>
    userSelector.getSagasLoaded(state),
  );
  const needLogout = useAppSelector(state => userSelector.needLogout(state));
  const company = useAppSelector(state =>
    enumsSelector.getCompany(state),
  ) as Company;

  useEffect(() => {
    setDefaultToken(userToken);
  }, [userToken]);

  useEffect(() => {
    if (userPersist?.firstname) {
      setUserExist(true);
    } else if (!userToken) {
      setUserExist(false);
    }
  }, [userPersist, userToken]);

  useEffect(() => {
    if (!userAuth && sagasLoaded) {
      dispatch(actionUsers.getAuthentication());
    }
  }, [dispatch, userAuth, sagasLoaded]);

  useEffect(() => {
    if (needLogout) {
      window.location.href = '/';
    }
  }, [needLogout]);

  return (
    <BrowserRouter>
      <Helmet titleTemplate="%s - Household" defaultTitle="Household">
        <meta name="description" content="Household app" />
      </Helmet>
      <LazyRender>
        <DynamicSagaLoader />
      </LazyRender>
      <Routes>
        <Route
          path={ROUTES.HOME}
          element={
            <PrepareRender loading={!userAuth} isPrivate={false}>
              <SignInPage />
            </PrepareRender>
          }
        />
        <Route
          path={`${ROUTES.DASHBOARD}/*`}
          element={
            <PrepareRender
              loading={!userAuth}
              isPrivate={true}
              isValid={userExist}
              state={userAuth?.user?.last_state}
            >
              <Accounting />
            </PrepareRender>
          }
        />
        {company?.household_root && (
          <Route
            path={`${ROUTES.PREQUALIFIED.MAIN}/*`}
            element={
              <PrepareRender
                loading={!userAuth}
                isPrivate={false}
                isValid={userExist}
              >
                <Prequalification />
              </PrepareRender>
            }
          />
        )}

        <Route
          path={'/*'}
          element={
            <PrepareRender
              isPrivate={false}
              loading={false}
              state={userAuth?.user?.last_state}
            >
              <PageNotFound />
            </PrepareRender>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
