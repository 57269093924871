import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
} from 'axios';

interface Props {
  headers?: object;
  body?: object | null;
  service: string;
  baseURL?: string;
}

const hostDomain = window.location.host;
let apiURL = 'http://localhost:5000/v1';
if (hostDomain) {
  if (hostDomain.indexOf('propertiesdashboard') !== -1) {
    apiURL = process.env.REACT_APP_DASH_API_URL || '';
  } else if (hostDomain.indexOf('usehousehold.com') !== -1) {
    apiURL = process.env.REACT_APP_BASE_API_URL || '';
  }
}

export const axiosInstance = axios.create({
  baseURL: apiURL,
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  return config;
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const apiError = error.response?.data.message;
    if (apiError) {
      return Promise.reject(new Error(apiError));
    }
    return Promise.reject(error);
  },
);

export const setDefaultToken = (token: string) => {
  axiosInstance.defaults.headers.common['x-hh-auth'] = token;
};

export const callService = (
  { headers, body, service }: Props,
  method: Method = 'post',
): Promise<any> => {
  let reqHeader = {};
  if (headers) {
    reqHeader = Object.assign(reqHeader, headers);
  }
  let request: AxiosRequestConfig = {
    headers: reqHeader,
    method,
    url: service,
    withCredentials: true,
  };
  if (method === 'post') {
    request.data = body;
  }
  return axiosInstance(request).then((response: any) => response.data);
};

export const putFileS3 = (
  signedUrl: string,
  filename: string,
  filetype: string,
  base64: any,
  progressCallback: (f: string, p: number) => void,
) => {
  const config = {
    onUploadProgress: (progressEvent: any) => {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      // progressCallback(filename, percentCompleted).
      progressCallback(filename, percentCompleted);
    },
    headers: {
      'Content-Type': filetype,
    },
  };

  const BufferFile = Buffer.from(
    base64.replace(/^data:application\/\w+;base64,/, ''),
    'base64',
  );
  return axiosInstance
    .put(signedUrl, Uint8Array.from(BufferFile), config)
    .then((response: any) => response.data);
};

export const callExternalService = (
  { headers, body, service, baseURL }: Props,
  method: Method = 'post',
): Promise<any> => {
  let reqHeader = {};
  if (headers) {
    reqHeader = Object.assign(reqHeader, headers);
  }
  let request: AxiosRequestConfig = {
    headers: reqHeader,
    method,
    url: service,
    baseURL,
  };
  if (method === 'post') {
    request.data = body;
  }
  return axios(request).then((response: any) => response.data);
};
