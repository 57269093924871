import styled, { createGlobalStyle } from 'styled-components';

type HomeContainerType = {
  maxWidth?: number;
};
export const PrequalifiedSiteContainer = styled.div<HomeContainerType>`
  @media screen and (max-width: 768px) {
    flex-direction: column;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  background: ${({ theme }) => theme.colors.lightBackgroud};
  padding-bottom: 150px;
  height: 100%;
`;

type TitleHeaderType = {
  align?: string;
  isMobile?: boolean;
};

export const HeaderContainer = styled.div<TitleHeaderType>`
  text-align: ${({ align }) => (align ? align : 'left')};
  .ant-progress {
    margin-right: ${({ isMobile }) => (!!isMobile ? '20px' : '50px')};
  }
  .ant-progress-text {
    font-family: ${({ theme }) => theme.fonts.Poppins};
    color: ${({ theme }) => theme.colors.black};
    font-weight: 500;
    font-size: 20px;
  }
`;

export const TitleHeader = styled.p<TitleHeaderType>`
  display: inline-block;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  color: ${({ theme }) => theme.colors.black};
  font-weight: 500;
  font-size: ${({ isMobile }) => (!!isMobile ? '24px' : '35px')};
  line-height: ${({ isMobile }) => (!!isMobile ? '30px' : '40px')};
  max-width: ${({ isMobile }) => (!!isMobile ? '200px' : '400px')};
  vertical-align: top;
`;

export const GlobalPrequalifyStyle = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.colors.lightBackgroud} !important;
  }
`;

export const FormContainer = styled.div`
  margin: 20px auto;
  position: relative;
  .rowFormPrequalify {
    margin-bottom: 15px;
  }
  .fade-enter {
    opacity: 0;
    transform: translateX(-25%);
  }
  .fade-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-enter-active {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-exit-active {
    opacity: 0;
    transform: translateX(25%);
  }
  .fade-enter-active,
  .fade-exit-active {
    transition: opacity 400ms, transform 400ms;
  }
  > div {
    width: 100%;
  }
  > div:nth-child(1) {
    width: 100%;
    background: inherit;
    padding: 0px;
    margin-bottom: 20px;
  }
  .verticalRadioGroup {
    margin-top: -15px;
    label {
      display: block;
    }
  }
  #prequalifiedIncomeData {
    > div {
      margin-bottom: 20px;
    }
  }
  .currencySelector {
    margin-bottom: 0px;
  }
  .searchCountry {
    .ant-select-selector {
      font-family: ${({ theme }) => theme.fonts.Poppins} !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      height: 60px !important;
      border-radius: 10px !important;
      border: 1px solid ${({ theme }) => theme.colors.grayLight};
      background: ${({ theme }) => theme.colors.white} !important;
      padding-left: 20px;
      color: ${({ theme }) => theme.colors.black} !important;
    }
    .ant-select-selection-search {
      left: 20px !important;
    }
    .ant-select-selection-item,
    .ant-select-selection-search-input {
      line-height: 60px;
      height: 60px !important;
    }
    .ant-select-selection-placeholder {
      line-height: 60px;
    }
  }
`;

export const LabelInputPreq = styled.span`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  color: ${({ theme }) => theme.colors.black};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 15px;
  li {
    margin: 10px 0px;
    overflow-wrap: break-word;
    word-break: break-all;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
  span {
    font-weight: 600;
  }
`;

type StepContainerType = {
  isMobile?: boolean;
};

export const StepsContainer = styled.div<StepContainerType>`
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: ${({ isMobile }) => (!!isMobile ? '0px' : '20px')};
  padding-top: ${({ isMobile }) => (!!isMobile ? '0px' : '15px')};
  font-family: ${({ theme }) => theme.fonts.Poppins};
  background: ${({ theme }) => theme.colors.softGray};
  border-top: 1px solid ${({ theme }) => theme.colors.grayLight};
  color: ${({ theme }) => theme.colors.whiteSoft};
  .ant-steps-item-title {
    font-weight: 600;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.realBlack} !important;
  }
  .ant-steps-item-description {
    font-weight: 300;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.black} !important;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: ${({ theme }) => theme.colors.gradientBlue} !important;
  }
  .ant-steps-item-finish
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background: ${({ theme }) => theme.colors.gradientBlue};
  }
  .ant-steps-item-process
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background: ${({ theme }) => theme.colors.gradientBlue};
  }
  .ant-steps-item-disabled {
    opacity: 0.4;
  }
`;
