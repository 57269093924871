import { useEffect, useState, useCallback } from 'react';
import {
  MemberHeaderContainer,
  LogoContainer,
  UserMenuItem,
  Container,
  LanguageButton,
  MenuDesktopContainer,
  GlobalMenuStyle,
  MenuButton,
  DesktopUserAvatar,
} from './styled';
import { Link, useNavigate } from 'react-router-dom';
import {
  AuthUser,
  GenericError,
  UserPersist,
  UserStates,
} from '@/core/interfaces';
import { GlobalOutlined } from '@ant-design/icons';
import { ROUTES } from '@/config/constant';
import {
  formatWalletAddress,
  getInitialsByName,
} from '@/core/utils/GenericFunctions';
import { Icon } from '@iconify/react';
import { Avatar, Menu, Dropdown, Button } from 'antd';
import { useTheme } from 'styled-components';
import CompanyLogo from '../CompanyLogo';

const Header = (props: Props) => {
  const {
    labels,
    userAuth,
    sagasLoaded,
    withBackground,
    logoHeight,
    withColor,
    userPersist,
    languages,
    getProviders,
    initLogout,
    callGetGlobalEnums,
  } = props;
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [isLogin, setIsLogin] = useState<null | boolean>(null);
  const theme = useTheme();

  useEffect(() => {
    if (sagasLoaded) {
      getProviders();
    }
  }, [sagasLoaded, getProviders]);

  const navigateTo = useCallback(
    (pageName: string) => {
      navigate(pageName);
    },
    [navigate],
  );

  useEffect(() => {
    if (userAuth?.address) {
      const addressTruncated = formatWalletAddress(userAuth.address, 2);
      const initials = getInitialsByName(userAuth.user);
      setUsername(initials || userAuth.ens || addressTruncated);
      setIsLogin(true);
    } else if (sagasLoaded && userAuth?.message) {
      setIsLogin(false);
    } else {
      setUsername('');
    }
  }, [userAuth, sagasLoaded]);

  useEffect(() => {
    if (!userAuth?.address && userPersist?.firstname) {
      const initials = getInitialsByName(userPersist);
      setUsername(initials || '');
    }
  }, [userAuth, userPersist]);

  const changeEnums = (languageDB: string) => {
    callGetGlobalEnums(languageDB);
  };

  console.log(
    isLogin,
    userAuth?.last_state,
    userAuth?.last_state === UserStates.COMPLETED,
  );
  return (
    <MemberHeaderContainer withBackground={withBackground}>
      <GlobalMenuStyle />
      <Container>
        <LogoContainer
          logoHeight={logoHeight}
          onClick={() => {
            if (isLogin) {
              navigateTo(ROUTES.HOME);
            }
          }}
        >
          {/* <img src={withColor ? logoColor : logo} alt="logo" /> */}
          <CompanyLogo mode={!!withColor ? 'light' : 'dark'} />
        </LogoContainer>
        <MenuDesktopContainer>
          {userAuth?.last_state === UserStates.COMPLETED && (
            <Dropdown
              overlay={
                <Menu>
                  {/* <Menu.Item key="home">
                  <Link to={ROUTES.DASHBOARD}>
                    <UserMenuItem>Home</UserMenuItem>
                  </Link>
                </Menu.Item> */}
                  <Menu.ItemGroup key="group_pm" title="Property Managment">
                    <Menu.Item key="propertieslistmenu">
                      <Link to={ROUTES.PROPERTY_MANAGMENT.MAIN_ROUTER}>
                        Your properties
                      </Link>
                    </Menu.Item>
                  </Menu.ItemGroup>
                  <Menu.ItemGroup
                    key="group_qualification"
                    title="Qualification"
                  >
                    <Menu.Item key="fpsmenu">
                      <Link to={ROUTES.QUALIFICATION.PFS_ROUTER}>
                        Personal Financial Statement
                      </Link>
                    </Menu.Item>
                  </Menu.ItemGroup>
                  <Menu.ItemGroup
                    key="group_useraccount"
                    title="User Account"
                    className="logoutMobile"
                  >
                    <Menu.Item key="logoutmobilemenu">
                      <UserMenuItem onClick={initLogout}>
                        {labels.logout}
                      </UserMenuItem>
                    </Menu.Item>
                  </Menu.ItemGroup>
                </Menu>
              }
              placement="bottom"
              trigger={['click']}
            >
              <MenuButton>
                <Button
                  shape="circle"
                  size="large"
                  type="link"
                  icon={
                    <Icon
                      color={theme.colors.link}
                      width={28}
                      icon={'ion:menu'}
                    />
                  }
                />
              </MenuButton>
            </Dropdown>
          )}
        </MenuDesktopContainer>
        <>
          <Dropdown
            overlay={
              <Menu>
                {languages?.map((language, indexLanguage) => {
                  const languageArray = language.split(':');
                  return (
                    <Menu.Item key={`${indexLanguage}-language`}>
                      <UserMenuItem
                        onClick={() => changeEnums(languageArray[1])}
                      >
                        {languageArray[0]}
                      </UserMenuItem>
                    </Menu.Item>
                  );
                })}
              </Menu>
            }
            placement="bottomRight"
            trigger={['click']}
          >
            <LanguageButton>
              <Button shape="circle" size="large" icon={<GlobalOutlined />} />
            </LanguageButton>
          </Dropdown>
          {((isLogin !== null && isLogin) || userPersist?.firstname) && (
            <DesktopUserAvatar>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key="0">
                      <UserMenuItem onClick={initLogout}>
                        {labels.logout}
                      </UserMenuItem>
                    </Menu.Item>
                  </Menu>
                }
                placement="bottomRight"
                trigger={['click']}
              >
                <Avatar
                  style={{
                    backgroundColor: theme.colors.secundary,
                    verticalAlign: 'middle',
                    cursor: 'pointer',
                  }}
                  size="large"
                >
                  {username}
                </Avatar>
              </Dropdown>
            </DesktopUserAvatar>
          )}
        </>
      </Container>
    </MemberHeaderContainer>
  );
};

export type Props = {
  labels: { [key: string]: string };
  userAuth: AuthUser;
  error: GenericError;
  sagasLoaded: boolean;
  withBackground?: boolean;
  logoHeight?: number;
  withColor?: boolean;
  clearError: () => void;
  getProviders: () => void;
  getAuthentication: () => void;
  initLogin: () => void;
  initLogout: () => void;
  userPersist: UserPersist;
  languages: string[];
  callGetGlobalEnums: (db: string) => void;
};

export default Header;
