import { createSelector } from 'reselect';
import { enumsKeys } from '@/core/reducers/enums';

const enumsFromState = (state: any) => state[enumsKeys.NAME];
const globalsEnums = (state: any) => state[enumsKeys.NAME][enumsKeys.GLOBAL];
const emptyString = '';
const emptyObject = '';
const isEnumsLoaded = createSelector(
  [enumsFromState],
  enums => enums[enumsKeys.LOADED],
);
const getLiteralEnum: (state: any, s: string) => string = createSelector(
  [globalsEnums, (_, literal) => literal],
  (enums, literal) => {
    return enums[literal] || emptyString;
  },
);
const getLiteralEnumObject: (
  state: any,
  s: string,
) => { [key: string]: unknown } = createSelector(
  [globalsEnums, (_, literal) => literal],
  (enums, literal) => {
    return enums[literal] || emptyObject;
  },
);
const getLiteralArray: (state: any, s: string) => Array<any> = createSelector(
  [globalsEnums, (_, literal) => literal],
  (enums, literal) => {
    return enums[literal] || emptyObject;
  },
);
const getCompany = createSelector(
  [enumsFromState],
  enums => enums[enumsKeys.COMPANY],
);

const selectors = {
  isEnumsLoaded,
  getLiteralEnum,
  getLiteralEnumObject,
  getLiteralArray,
  getCompany,
};
export { selectors as default };
