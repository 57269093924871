import { createSlice } from '@reduxjs/toolkit';

export const propertiesKeys = {
  NAME: 'properties',
  google_loaded: 'google_loaded',
};

const propertiesSlice = createSlice({
  name: propertiesKeys.NAME,
  initialState: {
    google_loaded: false,
  },
  reducers: {
    set(state, action) {
      const { key, value } = action.payload;
      const newState: any = { ...state };
      newState[key] = value;
      return newState;
    },
    reset() {
      return {
        google_loaded: false,
      };
    },
    resetKeys(state, action) {
      const { keys } = action.payload;
      const newState: any = { ...state };
      keys.forEach((k: string) => {
        newState[k] = null;
      });
      return newState;
    },
    getUserProperties() {},
    getPropertiesData(_state, _action) {},
    getPMProperty(_state, _action) {},
    getMonthlyDetail(_state, _action) {},
    getSummary() {},
    updateConfig(_state, _action) {},
    updatePropertyConfig(_state, _action) {},
    setGoogleLoaded(state) {
      const newState: any = { ...state };
      newState.google_loaded = true;
      return newState;
    },
  },
});

export const actionProperties = propertiesSlice.actions;

export default propertiesSlice.reducer;
