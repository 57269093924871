export const ROUTES = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  MORTGAGE: '/mortgage',
  ADMIN: '/admin',
  PREQUALIFIED: {
    MAIN: '/prequalify',
    FUNDS: '/prequalify/funds',
    FUNDS_ROUTER: '/funds',
    INCOME: '/prequalify/income',
    INCOME_ROUTER: '/income',
    RESULT: '/prequalify/result',
    RESULT_ROUTER: '/result',
    USFLOW: {
      MAIN: '/us',
      MAIN_ROUTER: '/prequalify/us',
      BANK_INCOME: '/us/banks_incomes',
      DOCUMENTS: '/docs',
      DOCUMENTS_ROUTER: '/prequalify/us/docs',
      PROCESSING: '/processing',
      PROCESSING_ROUTER: '/prequalify/us/processing',
    },
  },
  QUALIFICATION: {
    MAIN: '/qualification',
    MAIN_ROUTER: '/dashboard/qualification',
    PFS: '/pfs',
    PFS_ROUTER: '/dashboard/qualification/pfs',
  },
  PROPERTY_MANAGMENT: {
    MAIN: '/pm',
    MAIN_ROUTER: '/dashboard/pm',
    PROPERTY: '/property/:property_id',
  },
};
