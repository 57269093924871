import logo from '@/assets/images/logohh.svg';
import logoColor from '@/assets/images/logohhcolor.svg';
import { useAppSelector } from '@/core';
import { Company } from '@/core/interfaces';
import enumsSelector from '@/core/selectors/enums';

const CompanyLogo = (prop: Props) => {
  const { mode } = prop;

  const company = useAppSelector(state =>
    enumsSelector.getCompany(state),
  ) as Company;
  const isEnumsLoaded = useAppSelector(state =>
    enumsSelector.isEnumsLoaded(state),
  ) as boolean;

  return (
    <>
      {isEnumsLoaded ? (
        company?.params?.logo ? (
          <img src={company.params.logo as string} alt="logo" />
        ) : (
          <img src={mode !== 'light' ? logoColor : logo} alt="logo" />
        )
      ) : (
        <></>
      )}
    </>
  );
};

type Props = {
  mode?: string;
};

export default CompanyLogo;
