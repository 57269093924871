export const ContractsName = {
  HOUSEHOLD_POOL: 'HouseHoldPool',
  USDC: 'USDC',
  HOUSEHOLD: 'HouseHold',
  CONFIG: 'HHConfigs',
  HOUSEHOLD_POOL_TOKEN: 'HHPoolToken',
  MORTGAGE: 'Mortgage',
  MORTGAGE_POOL: 'MortgagePool',
  MORTGAGE_POOL_TOKEN: 'MortgagePoolToken',
};

export const CONSTANT = {
  MONTHS_IN_YEARS: 12,
  DAYS_IN_YEARS: 360,
  MINUTES_IN_HOURS: 60,
  DAYS_IN_MONTH: 30,
  USDC_DECIMALS: 6,
  DECIMALS: 6,
  OTP_LENGTH: 6,
  DATES: {
    DATES_DDMMYYYY: 'DD MMM YYYY',
    DATES_MMYYYY: 'MMM YYYY',
    DATES_MMYY: 'MMM YY',
    DATES_YYYY: 'YYYY',
    DATES_YYYYMM: 'YYYY-MM',
    DATES_YYYYMMDD: 'YYYY-MM-DD',
  },
  STATUS: {
    STRIPE: {
      SUCCEEDED: 'succeeded',
      VERIFY_NEEDED: 'verify_with_microdeposits',
    },
  },
  COLORS: [
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#cc1483',
    '#d69b00',
    '#003d95',
    '#d2fea2',
    '#00337c',
    '#008129',
    '#ff93f3',
    '#006100',
    '#ff8ce8',
    '#00fdff',
    '#8b0020',
    '#00accd',
    '#b56100',
    '#005fa4',
    '#650000',
    '#ffd6ff',
    '#5c0a00',
    '#f6b4ff',
    '#005181',
    '#843344',
  ],
};
