import { createSelector } from 'reselect';
import { errorKeys } from '@/core/reducers/error';

const errorFromState = (state: any) => state[errorKeys.NAME];

const getError = createSelector([errorFromState], error => error);

const getCustomError: (state: any, code: string) => string = createSelector(
  [errorFromState, (_, code) => code],
  (error, code) => {
    return error.custom ? error.custom[code] : undefined;
  },
);

const selectors = {
  getError,
  getCustomError,
};
export { selectors as default };
