import { createSlice } from '@reduxjs/toolkit';

export const poolKeys = {
  NAME: 'pool',
  ABIS: 'abis',
  BALANCE: 'balance',
};

const INTIAL_STATE = {
  [poolKeys.ABIS]: {},
  [poolKeys.BALANCE]: 0,
};

const poolSlice = createSlice({
  name: poolKeys.NAME,
  initialState: INTIAL_STATE,
  reducers: {
    set(state, action) {
      const { key, value } = action.payload;
      const newState: any = { ...state };
      newState[key] = value;
      return newState;
    },
    reset() {
      return {
        ...INTIAL_STATE,
        [poolKeys.BALANCE]: 0,
      };
    },
    resetKeys(state, action) {
      const { keys } = action.payload;
      const newState: any = { ...state };
      keys.forEach((k: string) => {
        newState[k] = null;
      });
      return newState;
    },
    updateABIS(state: any, action) {
      const {
        abiName,
        abi,
      }: {
        abiName: string;
        abi: unknown;
      } = action.payload;
      state[poolKeys.ABIS][abiName] = abi;
      return state;
    },
    getPoolBalance() {},
    getMortgagesList(_state, _action) {},
    getABIContract(_state, _action) {},
    makePoolDeposit(_state, _action) {},
    createMortgage(_state, _action) {},
    drawdownMortgage(_state, _action) {},
    getMortgageDetail(_state, _action) {},
    repaymentMortgage(_state, _action) {},
    getAmortization(_state, _action) {},
    getPoolPortfolio() {},
    getDepositRates() {},
  },
});

export const actionPools = poolSlice.actions;

export default poolSlice.reducer;
