import { createSlice } from '@reduxjs/toolkit';

export const providersKeys = {
  NAME: 'providers',
};

const INTIAL_STATE = Object.keys(providersKeys).reduce(
  (acum: object, key: string) => ({
    ...acum,
    [key]: null,
  }),
  {},
);
const providerSlice = createSlice({
  name: providersKeys.NAME,
  initialState: INTIAL_STATE,
  reducers: {
    set(state, action) {
      const { key, value } = action.payload;
      const newState: any = { ...state };
      newState[key] = value;
      return newState;
    },
    reset() {
      return INTIAL_STATE;
    },
    resetKeys(state, action) {
      const { keys } = action.payload;
      const newState: any = { ...state };
      keys.forEach((k: string) => {
        newState[k] = null;
      });
      return newState;
    },
    getProvidersList() {},
  },
});

export const actionProviders = providerSlice.actions;

export default providerSlice.reducer;
