export interface Payload {
  type: any;
  payload: any;
}

export enum CompanyParamsEnums {
  favicon = 'favicon',
  logo = 'logo',
}

export type CompanyParams = { [key in CompanyParamsEnums]?: unknown };
export interface Company {
  company_name: string;
  domain: string;
  id_company: number;
  identifier: string;
  household_root?: boolean;
  params: CompanyParams;
}

export enum PRESIGN_HANDLER {
  PROPERTIES = 'properties',
  QUALIFICATION = 'properties',
}

export interface UserBasic {
  firstname: string;
  lastname: string;
  email: string;
  country_id?: number;
  last_state?: UserStates;
  login_redirect?: string;
}

export enum UserStates {
  PREQUALIFICATION_FORM = 'pre_form',
  PREQUALIFICATION_PROCESSING = 'pre_processing',
  PREQUALIFIED = 'prequalified',
  COMPLETED = 'completed',
}

export enum UserParams {
  PM_SERVICES = 'pm_services',
  pm_show_gross_monthly = 'pm_show_gross_monthly',
}
export type UserParamsObject = { [key in UserParams]?: unknown };
export interface AuthUser {
  message?: string | undefined;
  address?: string | undefined;
  ens?: string | undefined;
  balance?: number;
  user?: UserBasic;
  token?: string;
  last_state?: UserStates;
  new_created?: boolean;
  login_redirect?: string;
}

export interface UserPersist {
  firstname: string;
  lastname: string;
  email?: string;
  params?: UserParams[];
}

export interface GenericError {
  status?: boolean;
  message?: string;
  code?: string;
  custom?: any;
}

export interface PageNotionItem {
  type: string;
  text: Array<PageNotionItemText>;
}

export interface PageNotionItemText {
  type: string;
  text: {
    content: string;
    link: null | {
      url: string;
    };
  };
  annotations: {
    bold: boolean;
    italic: boolean;
    strikethrough: boolean;
    underline: boolean;
    code: boolean;
    color: string;
  };
}

export interface ABIResponse {
  abi?: undefined | string;
  address?: string;
}

export interface PoolBalance {
  balance: number;
}

export interface Accounting {
  balance: number;
  interestOwed: number;
  nextDueTime: number;
  totalInterestAccrued: number;
}
export interface Mortgage {
  mortgage: string;
  borrower: string;
  name: string;
  email?: string;
  clientId?: string;
  amount: number | string;
  payback_term: number;
  repayments: number;
  ear: number | string;
  grace_period: number;
  late_fee: number;
  mortgage_default: number;
  accounting?: Accounting | null | undefined;
}

export enum MortgageChargeType {
  TAX = 'tax',
  INSURANCE = 'insurance',
}

export interface MortgageCharge {
  id_mortgage_charge: number;
  type: MortgageChargeType;
  charge_amount: number;
  label?: string;
}

export interface MortgageDetail {
  mortgage: string;
  nextPayment: number;
  nextPaymentDate: string;
  nextPaymentDays: number;
  debtTotal: number;
  debtTotalDate: string;
  debtPrincipal: number;
  debtInterest: number;
  paymentHistory: PaymentHistory[];
  initalLoanAmount: number;
  charges?: MortgageCharge[];
  nextPaymentWithCharge?: number;
}

export interface AmortizationRow {
  repayment: number;
  payment_date: string;
  payment: number;
  principal: number;
  interest: number;
  balance: number;
  charges?: {
    [key: string]: {
      type: string;
      amount: number;
    };
  };
}
export interface PaymentHistory {
  trx: string;
  amount: number;
  datetime: string;
  state: string;
  stateSuccess: boolean;
  paymentNumber?: number;
  charges?: { [key: string]: number };
  payment?: number;
  time: number;
}

export interface PortfolioTransaction {
  trx: string;
  amount: number | string;
  releaseDate: string;
  tokenId: number;
}
export interface PoolPortfolio {
  balance: number;
  interest: number;
  transactions: Array<PortfolioTransaction>;
  kyc?: {
    active: boolean;
    message?: string;
  };
}

export interface OTPValidation {
  token: string;
  email: string;
  otp: string;
  func: string;
}

export interface PersonaReq {
  email: string;
  address: string;
  templateId: string;
  environment: string;
}

export interface PersonaStatus {
  inquiryId?: string;
  status?: string;
  fields?: { [key: string]: string };
}

export interface DepositRate {
  amount: number;
  rate: number;
  days: number;
  label: string;
}

export interface WyreTransfer {
  sourceCurrency?: string;
  sourceAmount?: string;
  dest?: string;
  destCurrency?: string;
  destAmount?: string;
  amountIncludesFees?: boolean;
  completedAt?: string;
  cancelledAt?: string;
  failureReason?: string;
  status?: string;
  id?: string;
  message?: string;
  action?: string;
}

export interface StripeSetupParams {
  clientSecret: string;
  params: {
    payment_method_type: string;
    payment_method_data: {
      billing_details: {
        name: string;
        email: string;
      };
    };
  };
  expand: string[];
}

export interface StripePaymentMethod {
  billing_details: {
    address: {
      city: string;
      country: string;
      line1: string;
      line2: string;
      postal_code: string;
      state: string;
    };
    email: string;
    name: string;
    phone: string;
  };
  created: number;
  customer: string;
  id: string;
  livemode: boolean;
  object: string;
  type: string;
  us_bank_account: {
    account_holder_type: string;
    account_type: string;
    bank_name: string;
    financial_connections_account: string;
    fingerprint: string;
    last4: string;
    routing_number: string;
  };
}

export interface GraphStatusIndex {
  data: {
    indexingStatusForCurrentVersion: {
      chains: Array<{
        latestBlock: {
          hash: string;
          number: number;
        };
      }>;
    };
  };
}

export interface StripeNextActionConfirm {
  status: string;
  next_action_date?: string;
}

export interface HousePaymentMethod {
  paymentMethod: string;
  status?: string;
  institution?: string;
  next_action?: string;
  next_action_type?: string;
  next_action_date?: string;
}

export interface BankAccountMask {
  country: string;
  data: {
    account: Array<{
      mask: string;
      name: string;
    }>;
    institution: {
      name: string;
    };
    date_last_updated: string;
  };
}

export interface Country {
  id_country: number;
  country: string;
  short_code: string;
  short_code_v2?: string;
}

export enum SupportCountries {
  US = 'US',
  USA = 'USA',
  MX = 'MX',
  MEX = 'MEX',
  COL = 'COL',
  CO = 'CO',
  BRA = 'BRA',
  BR = 'BR',
}

export interface LetterData {
  max_house_price: number;
  loan_amount: number;
  mortgage_repayment: number;
  house_tax: number;
}

export interface PrequalificationLetter {
  terms: {
    rate: number;
    years: number;
  };
  assets: LetterData;
  income: LetterData;
  not_apply: boolean;
  paySelected: string;
}

export interface IncomeShortMask {
  accounts: {
    account_id: string;
    pay_rate: string;
    pay_amount: number;
    pay_frequency: string;
  }[];
  updated_at: string;
  jobs: {
    employer: string;
    employee: string;
    tax_year: string;
  }[];
}
export interface PrequalificationData {
  banksAccount: BankAccountMask[];
  prequalifyForm: unknown[];
  letter: PrequalificationLetter[];
  income: IncomeShortMask[];
  documents: Array<{
    documents: string[];
  }>;
}

export enum PreqFormIncomeEnum {
  house_has_mortgage = 'house_has_mortgage',
  house_hoa = 'house_hoa',
  house_mortgage = 'house_mortgage',
  house_price = 'house_price',
  house_owner = 'house_owner',
  house_tax = 'house_tax',
  job_company = 'job_company',
  rent = 'rent',
  job_income = 'job_income',
  currency = 'currency',
  shares_values = 'shares_values',
  another_balances = 'another_balances',
  have_rfc = 'have_rfc',
}
export interface PreqFormIncome {
  [PreqFormIncomeEnum.house_has_mortgage]?: string;
  [PreqFormIncomeEnum.house_owner]?: string;
  [PreqFormIncomeEnum.job_company]?: string;
  [PreqFormIncomeEnum.currency]?: string;
  [PreqFormIncomeEnum.house_hoa]?: number;
  [PreqFormIncomeEnum.house_mortgage]?: number;
  [PreqFormIncomeEnum.house_price]?: number;
  [PreqFormIncomeEnum.house_tax]?: number;
  [PreqFormIncomeEnum.rent]?: number;
  [PreqFormIncomeEnum.job_income]?: number;
  [PreqFormIncomeEnum.have_rfc]?: string;
}

export enum FormInputTypes {
  TEXT = 'text',
  PHONE = 'phone',
  EMAIL = 'email',
  MONEY = 'money',
  NUMBER = 'number',
  PERCENTAGE = 'percentage',
  ACCUMULATIVE = 'accumulative',
  RESULT = 'result',
  REFERENCE = 'reference',
  GOOGLE_ADDRESS_PROPERTY = 'google_address_property',
  GOOGLE_ADDRESS_FORM = 'google_address_form',
  SWITCH = 'switch',
  DATE = 'date',
}

export enum FormJSONTypes {
  ARRAY = 'array',
  DYNAMIC_ARRAY = 'dynamic_array',
  CATEGORY = 'category',
  BREAK = 'break',
  ROW = 'row',
}

export enum DynamicFormType {
  financial_statement = 'financial_statement',
  pm_property = 'pm_property',
  pm_property_config = 'pm_property_config',
}

export enum DynamicFormUserStatus {
  completed = 'completed',
  draft = 'draft',
  approved = 'approved',
  rejected = 'rejected',
}
export interface DynamicFormTemplate {
  template: {
    form: any[];
    type?: string;
    id_dynamic_form?: number;
  };
  response?: {
    form: {
      [k: string]: any;
    };
    status: DynamicFormUserStatus;
    updated_at: string;
  };
}

export type dynamicAccType = {
  [name: string]: number;
};
export type DynamicSharedParams = {
  [name: string]: string;
};

export interface UserDynamicFormSave {
  id_dynamic_form: number;
  form: unknown;
  is_draft?: boolean;
  reference_id?: number;
}

export interface MortgagePropertyData {
  property_address: string;
  year_acquired: string;
  heldname: string;
  purchase_price: number;
  market_value: number;
  lender_name: string;
  mortgage_amount: number;
  payment_amount: number;
}

export interface GoogleAddress {
  postalCode: string;
  state: string;
  city: string;
  neighborhood: string;
  fullAddress: string;
  propertyData?: MortgagePropertyData;
}

export enum S3Action {
  PUT = 'put',
  GET = 'get',
}

export interface FileMask {
  name: string;
  type?: string;
}

export enum PropertyChargesTypes {
  tax = 'tax',
  insurance = 'insurance',
  mvr = 'mvr',
  hoa = 'hoa',
  maintenance = 'maintenance',
  interest = 'interest',
  rent = 'rent',
  pm2_services = 'pm2_services',
  pm_services = 'pm_services',
  mortgage_payment = 'mortgage_payment',
  nocharge = 'nocharge',
  other_fees = 'other_fees',
  owner_fees_linens = 'owner_fees_linens',
  merchant_fee = 'merchant_fee',
  credits = 'credits',
}

export type PropertyChargeAttachment = {
  name: string;
  url: string;
};
export interface PropertyCharge {
  key?: string;
  id_property_charge?: number;
  value: number;
  is_expense: boolean;
  label: string;
  type: PropertyChargesTypes;
  period?: string;
  created_at?: string;
  attachments?: PropertyChargeAttachment[];
}

export interface AnnualPropertyData {
  key?: string;
  label: string;
  value: number;
  type: string;
  yield?: number;
  ofrent?: number;
  isTotal?: boolean;
}
export interface PropertyAnualIncome {
  [type: string]: {
    [monthYear: string]: number;
  };
}
export interface Property {
  id_property?: number;
  user_id?: number;
  address: string;
  unit_name?: string;
  zipcode?: string;
  external_url?: string;
  image?: string;
  acquisition_date: string;
  cost: number;
  mortgage: number;
  market_value: number;
  loan_balance: number;
  equity: number;
  appreciation?: number;
  yearsOwned?: number;
  CAGR?: number;
  external_data?: MortgagePropertyData;
  charges?: PropertyCharge[];
  rent_date?: string;
  rent_account?: string;
  annual_income?: PropertyAnualIncome;
  annual_data?: AnnualPropertyData[];
  periodic_charges?: PropertyCharge[];
}
export interface ChartIncomeGeneric {
  [k: string]: string | number;
}

export interface ChartExpense {
  detail: string;
  value: number;
  label: string;
  periodic_value?: number;
  charge_date: string;
}
export interface ChartIncomeTrx {
  detail: string;
  value: number;
  charge_date: string;
}
export interface ChartExpenseData {
  [k: string]: ChartExpense[];
}
export interface ChartIncomeData {
  date?: string;
  income?: number;
  property?: string;
  expenses?: number;
  net?: number;
  expenses_total?: number;
  property_primary?: string;
  property_secundary?: string;
  expenses_data?: ChartExpenseData;
  lastMonthlyIncomeTrx?: ChartIncomeTrx[];
}

export interface PropertyDashboard {
  id_property: number;
  address: string;
  unit_name?: string;
  acquisition: {
    cost: number;
    original_loan: number;
    equity: number;
  };
  market_value: {
    appraisal: number;
    loan_balance: number;
    net_value: number;
  };
  yields: {
    cost: number;
    appraisal: number;
    gross_cost: number;
    gross_appraisal: number;
  };
  annual_data: { [date: string]: number };
}
export interface PropertiesSummary {
  properties_count: number;
  total_market_value: number;
  total_cost: number;
  total_monthly_income: number;
  total_annual_income: number;
  total_annual_net: number;
  total_loan_balance: number;
  total_net_monthly: number;
  properties_income: {
    property: string;
    income: number;
  }[];
  last_incomes: {
    date: string;
    income: number;
    expenses: number;
    net: number;
  }[];
  properties: PropertyDashboard[];
  params?: UserParamsObject;
  date_filter: string;
}
