import { useState, useEffect } from 'react';

const getWindowDimensions = () => {
  const { innerWidth: width } = window;
  return width < 768;
};

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

export default useIsMobile;
