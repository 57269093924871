import { createSlice } from '@reduxjs/toolkit';

export const enumsKeys = {
  NAME: 'enums',
  GLOBAL: 'GLOBAL',
  LOADED: 'LOADED',
  COMPANY: 'COMPANY',
};

const INTIAL_STATE = {
  [enumsKeys.GLOBAL]: null,
  [enumsKeys.COMPANY]: null,
  [enumsKeys.LOADED]: false,
};
const enumsSlice = createSlice({
  name: enumsKeys.NAME,
  initialState: INTIAL_STATE,
  reducers: {
    set(state, action) {
      const { key, value } = action.payload;
      const newState: any = { ...state };
      newState[key] = value;
      return newState;
    },
    reset() {
      return {};
    },
    resetKeys(state, action) {
      const { keys, defaultValue = null } = action.payload;
      const newState: any = { ...state };
      keys.forEach((k: string) => {
        newState[k] = defaultValue;
      });
      return newState;
    },
    getGlobalEnums(_state, _action) {},
  },
});

export const actionEnums = enumsSlice.actions;

export default enumsSlice.reducer;
