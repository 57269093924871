import { createSelector } from 'reselect';
import { persistKeys } from '@/core/reducers/persist';

const persistFromState = (state: any) => state[persistKeys.NAME];

const getUserToken = createSelector(
  [persistFromState],
  persist => persist[persistKeys.USER_TOKEN],
);
const userPersist = createSelector(
  [persistFromState],
  persist => persist[persistKeys.USER_PERSIST],
);
const selectors = {
  getUserToken,
  userPersist,
};
export { selectors as default };
