import { store, useAppDispatch } from '@/core';
import { actionUsers } from '@/core/reducers/users';
import { useEffect } from 'react';

const DynamicSagaLoader = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    import('@/core/sagas/dynamics')
      .then(async sagas => {
        await store.injectSaga('dynamicsagas', sagas.default);
      })
      .finally(() => {
        dispatch(actionUsers.sagaLoaded());
      });
  }, [dispatch]);
  return <></>;
};

export default DynamicSagaLoader;
