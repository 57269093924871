import { DepositRate } from '../interfaces';
import { CONSTANT } from './constant';

const formatterUSD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
const formatterUSDNoDecimal = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});
export const formatWalletAddress = (
  address: string | undefined | null,
  truncate: number = 4,
) => {
  if (!address) return '';
  const addressTruncated = `${address.substring(
    0,
    truncate,
  )}...${address.substring(address.length - truncate)}`;
  return addressTruncated;
};

export const formatDollar = (
  amount: string | number | null,
  withDecimal = true,
) => {
  if (!amount && amount !== 0) return '';
  const amountFormat = parseFloat(`${amount}`);
  if (withDecimal) {
    return formatterUSD.format(amountFormat);
  }
  return formatterUSDNoDecimal.format(amountFormat);
};

export const formatNumbersK = (amount: string | number | null) => {
  if (!amount && amount !== 0) return '';
  const amountFormat = parseFloat(`${amount}`);
  return `${(amountFormat / 1000).toFixed(1)}${amountFormat > 0 ? 'K' : ''}`;
};

export const formatPercentage = (number: string | number | null) => {
  if (!number) return '';
  const percentage = parseFloat(`${number}`).toFixed(2);
  return `${percentage}%`;
};

export const dollarToUSDC = (number: number) => {
  return parseInt((number * 1000000).toPrecision(10));
};

export const getInitialsByName = (user?: {
  firstname: string;
  lastname: string;
}) => {
  if (!user) {
    return null;
  }
  return user.firstname[0] + user.lastname[0];
};

export const anyToNumber = (anyNumber: unknown): number => {
  return parseFloat(parseFloat(`${anyNumber}`).toFixed(CONSTANT.DECIMALS));
};

export const calInvestment = (
  params: {
    days: number;
    amount: number;
  },
  rates: DepositRate[],
) => {
  let rate = rates.find(
    r => r.amount <= params.amount && params.days === r.days,
  );
  if (!rate) {
    rate = rates.find(r => params.days === r.days);
  }
  if (!rate) {
    return null;
  }
  const interest =
    (((rate.rate / CONSTANT.DAYS_IN_YEARS) * rate?.days) / 100) * params.amount;
  const total = anyToNumber(interest) + params.amount;
  return {
    rate: rate?.rate,
    totalReceived: total,
  };
};

export const formatterInputNumber = (value: string | number) => {
  return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const parserInputNumber = (value: string | undefined) => {
  return value!.replace(/\$\s?|(,*)/g, '');
};

export const loadScript = (url: string, callback: () => void) => {
  let script = document.createElement('script') as any;
  script.type = 'text/javascript';

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }
  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
};

export const getBase64 = (file: any): Promise<string | ArrayBuffer | null> => {
  return new Promise(resolve => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
  });
};
