import styled, { useTheme } from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import CompanyLogo from '../CompanyLogo';

const LoadingViewStyled = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
`;
const LoadingImage = styled.div`
  img {
    height: auto;
    width: 100%;
  }
`;

const MarginDiv = styled.div`
  width: 250px;
  margin: auto;
  margin-top: calc(50vh - 150px);
`;

const LoadingView = (props?: { mode?: string }) => {
  const { mode } = props || {};
  const theme = useTheme();
  return (
    <LoadingViewStyled>
      <MarginDiv>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 24,
                color:
                  mode === 'light' ? theme.colors.primary : theme.colors.white,
              }}
              spin
            />
          }
        />
        <LoadingImage>
          <CompanyLogo mode={mode} />
        </LoadingImage>
      </MarginDiv>
    </LoadingViewStyled>
  );
};
export default LoadingView;
