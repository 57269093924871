import { createSlice } from '@reduxjs/toolkit';

export const depositKeys = {
  NAME: 'deposits',
};

const depositSlice = createSlice({
  name: depositKeys.NAME,
  initialState: {},
  reducers: {
    set(state, action) {
      const { key, value } = action.payload;
      const newState: any = { ...state };
      newState[key] = value;
      return newState;
    },
    reset() {
      return {};
    },
    resetKeys(state, action) {
      const { keys } = action.payload;
      const newState: any = { ...state };
      keys.forEach((k: string) => {
        newState[k] = null;
      });
      return newState;
    },
    getPaymentMethod() {},
    getNewPlaidToken() {},
    getStripeSetupIntent() {},
    getProcessorToken(_state, _action) {},
    initTransferFlow(_state, _action) {},
    createPaymentMethod(_state, _action) {},
    createWyrePlaidUser(_state, _action) {},
    confirmStripePaymentMethod() {},
  },
});

export const actionDeposit = depositSlice.actions;

export default depositSlice.reducer;
