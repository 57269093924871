import { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState, AppDispatch } from '@/core';
import { actionEnums } from '@/core/reducers/enums';
import enumsSelector from '@/core/selectors/enums';
import AppRouter from './router';
import LoadingView from './components/LoadingView';

const AppRender = ({
  isEnumsLoaded,
  getEnums,
}: {
  isEnumsLoaded: boolean;
  getEnums: () => void;
}) => {
  useEffect(() => {
    getEnums();
  }, [getEnums]);

  return <>{isEnumsLoaded ? <AppRouter /> : <LoadingView />}</>;
};

export default connect(
  (state: RootState) => {
    return {
      isEnumsLoaded: enumsSelector.isEnumsLoaded(state),
    };
  },
  (dispatch: AppDispatch) => ({
    getEnums: () => {
      dispatch(actionEnums.getGlobalEnums({}));
    },
  }),
)(AppRender);
