import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware, { Task } from 'redux-saga';
import rootSaga from '@/core/sagas';
import rootReducers from '@/core/reducers';

declare module 'redux' {
  export interface Store {
    injectSaga: (key: string, saga: SagaType) => void;
  }
}

const persistConfig = {
  key: process.env.REACT_APP_STORE_KEY || 'development-key',
  storage: AsyncStorage,
  whitelist: ['persist'],
  transforms: [
    encryptTransform({
      secretKey: 'my-super-secret-key',
    }),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

let sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(sagaMiddleware),
});

type SagaType = typeof rootSaga;
type SagaFunctionType = typeof sagaMiddleware.run;
const createSagaInjector = (runSaga: SagaFunctionType, rootSaga: SagaType) => {
  const injectedSagas = new Map<string, Task>();
  const isInjected = (key: string) => injectedSagas.has(key);

  const injectSaga = (key: string, saga: SagaType) => {
    if (isInjected(key)) return;
    const task = runSaga(saga);
    injectedSagas.set(key, task);
  };
  injectSaga('root', rootSaga);
  return injectSaga;
};
store.injectSaga = createSagaInjector(sagaMiddleware.run, rootSaga);

const persistor = persistStore(store);

export { store, persistor };
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
