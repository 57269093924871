import { takeLatest, put, call } from 'redux-saga/effects';
import { actionEnums, enumsKeys } from '@/core/reducers/enums';
import { actionsError } from '@/core/reducers/error';
import * as api from '@/core/api/api-services';
import { Company, Payload } from '../interfaces';

function* handleGetGlobalEnum({ payload }: Payload) {
  try {
    const { db } = payload;
    const respond: {
      enums: Record<string, unknown>;
      companyDomain?: Company;
    } = yield call(
      api.callService,
      {
        service: 'home/' + (db ? `?database=${db}` : ''),
      },
      'get',
    );
    if (respond?.enums) {
      yield put(
        actionEnums.set({
          key: enumsKeys.GLOBAL,
          value: respond.enums,
        }),
      );
      yield put(
        actionEnums.set({
          key: enumsKeys.LOADED,
          value: true,
        }),
      );
      if (respond.companyDomain) {
        yield put(
          actionEnums.set({
            key: enumsKeys.COMPANY,
            value: respond.companyDomain,
          }),
        );
      }
    } else {
      yield put(actionsError.setError());
    }
  } catch (error: any) {
    yield put(actionsError.setError({ message: error.message }));
  }
}

const sagas = [
  takeLatest(actionEnums.getGlobalEnums.type, handleGetGlobalEnum),
];

export { sagas as default };
